<template>
	<div>
		<a-spin :spinning="loading">
		<a-row   :gutter="30">
			<a-col :span="8">
				<div class="count-box">
					<div class="flex alcenter space">
						<div class="flex alcenter">
							<i class="iconfont iconhome_fun01 ft32" style="color:#4772FF;"></i>
							<span class="ft18 cl-main ftw600 ml15">商户付费 </span>
						</div>
		
						<div>
							<a-radio-group v-model="type" @change="changeType" button-style="solid">
								<a-radio-button :value="1">
									今日
								</a-radio-button>
								<a-radio-button :value="2">
									本月
								</a-radio-button>
								<a-radio-button :value="3">
									今年
								</a-radio-button>
							</a-radio-group>
						</div>
					</div>
		
					<div class="mt40">
						<div class="flex">
							<div style="width: 50%;" class="flex center">
								<div>
									<div class="flex">
										<div class="home-count-color-item" style="background: #4772FF;"></div>
										<div class="ml10">
											<div class="ft14 ftw400 cl-notice">购买小程序费用</div>
											<div class="mt8 ft20 ftw600 cl-main">¥{{tubiao.buy}}</div>
										</div>
									</div>
		
									<div class="flex mt30">
										<div class="home-count-color-item" style="background: #FE7C23;"></div>
										<div class="ml10">
											<div class="ft14 ftw400 cl-notice">授权码价值</div>
											<div class="mt8 ft20 ftw600 cl-main">{{tubiao.code}}</div>
										</div>
									</div>
		
									<div class="flex mt30">
										<div class="home-count-color-item" style="background: #00BA26;"></div>
										<div class="ml10">
											<div class="ft14 ftw400 cl-notice"> 短信费用</div>
											<div class="mt8 ft20 ftw600 cl-main">¥{{tubiao.sms}}</div>
										</div>
									</div>
								</div>
							</div>
							<div style="width: 50%;" class="flex center">
								<div style="width: 200px;">
									<ve-ring :extend="chartExtend" :data="chartData" :settings="chartSettings" :legend-visible="true" :colors="ringChart.colors"
									 height="200px" width="200px"></ve-ring>
								</div>
							</div>
						</div>
					</div>
		
				</div>
			</a-col>
			<a-col :span="16">
				<div class="count-box-right">
					<a-row :gutter="[16,16]">
						<a-col :span="12">
							<div class="count-box-right-item">
								<div class="flex alcenter">
									<i class="iconfont iconhome_fun02 ft32" style="color:#FE7C23;"></i>
									<span class="ft18 cl-main ftw600 ml15">平台用户注册数 </span>
								</div>
								<div class="flex mt20">
									<div class="col2 text-center">
										<div class="ft16 cl-info">今日</div>
										<div class="ft18 mt8 ftw600 cl-black">{{tongji == null ? '-' : tongji.account.today}}</div>
									</div>
									<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
										<div class="ft16 cl-info">本月</div>
										<div class="ft18 mt8 ftw600 cl-black">{{tongji == null ? '-' : tongji.account.month}}</div>
									</div>
									<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
										<div class="ft16 cl-info">本年度</div>
										<div class="ft18 mt8 ftw600 cl-black">{{tongji == null ? '-' : tongji.account.year}}</div>
									</div>
								</div>
							</div>
						</a-col>
						<a-col :span="12">
							<div class="count-box-right-item">
								<div class="flex alcenter">
									<i class="iconfont iconhome_fun03 ft32" style="color:#00BA26;"></i>
									<span class="ft18 cl-main ftw600 ml15">小程序注册数 </span>
								</div>
								<div class="flex mt20">
									<div class="col2 text-center">
										<div class="ft16 cl-info">今日</div>
										<div class="ft18 mt8 ftw600 cl-black">{{tongji == null ? '-' : tongji.register.today}}</div>
									</div>
									<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
										<div class="ft16 cl-info">本月</div>
										<div class="ft18 mt8 ftw600 cl-black">{{tongji == null ? '-' : tongji.register.month}}</div>
									</div>
									<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
										<div class="ft16 cl-info">本年度</div>
										<div class="ft18 mt8 ftw600 cl-black">{{tongji == null ? '-' : tongji.register.year}}</div>
									</div>
								</div>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[16,16]">
						<a-col :span="12">
							<div class="count-box-right-item">
								<div class="flex alcenter">
									<i class="iconfont iconhome_fun04 ft32" style="color:#FEC75A;"></i>
									<span class="ft18 cl-main ftw600 ml15">商户数增长 </span>
								</div>
								<div class="flex mt20">
									<div class="col2 text-center">
										<div class="ft16 cl-info">今日</div>
										<div class="ft18 mt8 ftw600 cl-black">{{tongji == null ? '-' : tongji.shop.today}}</div>
									</div>
									<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
										<div class="ft16 cl-info">本月</div>
										<div class="ft18 mt8 ftw600 cl-black">{{tongji == null ? '-' : tongji.shop.month}}</div>
									</div>
									<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
										<div class="ft16 cl-info">本年度</div>
										<div class="ft18 mt8 ftw600 cl-black">{{tongji == null ? '-' : tongji.shop.year}}</div>
									</div>
								</div>
							</div>
						</a-col>
						<a-col :span="12">
							<div class="count-box-right-item">
								<div class="flex alcenter">
									<i class="iconfont iconhome_fun05 ft32" style="color:#01B3FE;"></i>
									<span class="ft18 cl-main ftw600 ml15">GMV(小程序支付金额) </span>
								</div>
								<div class="flex mt20">
									<div class="col2 text-center">
										<div class="ft16 cl-info">今日</div>
										<div class="ft18 mt8 ftw600 cl-black">¥{{tongji == null ? '-' : tongji.gmv.today}}</div>
									</div>
									<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
										<div class="ft16 cl-info">本月</div>
										<div class="ft18 mt8 ftw600 cl-black">¥{{tongji == null ? '-' : tongji.gmv.month}}</div>
									</div>
									<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
										<div class="ft16 cl-info">本年度</div>
										<div class="ft18 mt8 ftw600 cl-black">¥{{tongji == null ? '-' : tongji.gmv.year}}</div>
									</div>
								</div>
							</div>
						</a-col>
					</a-row>
				</div>
			</a-col>
		</a-row>
		</a-spin>
	</div>
</template>

<script>
	import Vue from 'vue'
	import VCharts from 'v-charts';
	Vue.use(VCharts);
	export default {
		data() {
			this.chartExtend = {
				legend: {
					show: false
				}, //隐藏legend
				series: {
					center: ['50%', '50%']
				}
			};
			return {
				tongji:null,
				loading:false,
				type:1,
				tubiao:{
					buy:0,
					sms:0,
					code:0,
				},
				chartSettings: {
					radius: ['55%', '80%'],
					center: ['50%', '20%'],
					label: {
						normal: {
							show: false,
							position: 'center'
						},
						emphasis: {
							show: false,
							testStyle: {
								fontSize: '30',
								fontWeight: 'bold'
							}
						},
					},
				},
				ringChart: {
					colors: ["#5DBCFF", "#FFA100", "#00C657", "#5E40FF"]
				},



				chartData: {
					columns: ['name', 'count'],
					rows: [{
							'name': '购买小程序费用',
							'count': 10,
						},
						{
							'name': '授权码费用',
							'count': 10,
						},
						{
							'name': ' 短信费用',
							'count': 10,
						},
					]
				},
			}
		},
		created(){
			this.loaddata();
		},
		methods:{
			changeType(e){
				let type = e.target.value;
				this.type = type;
				this.showTubiao();
			},
			showTubiao(){
				switch(this.type){
					case 1:
						this.tubiao = this.tongji.order.today;
					break;
					case 2:
					    this.tubiao = this.tongji.order.month;
					break;
					case 3:
						this.tubiao = this.tongji.order.year;
					break;
				}
				this.chartData = {
					columns: ['name', 'count'],
					rows: [{
							'name': '购买小程序费用',
							'count': this.tubiao.buy,
						},
						{
							'name': '授权码费用',
							'count': this.tubiao.code,
						},
						{
							'name': ' 短信费用',
							'count': this.tubiao.sms,
						},
					]
				};
				
			},
			loaddata(){
				this.loading = true;
				this.$http.api('platform/admin/getCountData').then(res=>{
					this.tongji = res;
					this.loading = false;
					this.showTubiao();
				}).catch(res=>{
					this.loading = false;
				});
			}
		}
	}
</script>

<style>
	.count-box {
		width: 100%;
		height: 340px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 24px;
	}

	.count-box div {
		line-height: 1;
	}

	.home-count-color-item {
		width: 10px;
		height: 10px;
		border-radius: 2px;
	}

	.count-box-right {
		height: 340px;
	}

	.count-box-right-item {
		width: 100%;
		height: 160px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 20px;
		line-height: 1;
	}
</style>
